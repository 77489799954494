export const Show = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M11.9841 5.24754C8.33067 5.24754 4.58301 7.36207 1.6327 11.5911C1.54849 11.7132 1.50234 11.8574 1.50009 12.0057C1.49783 12.154 1.53958 12.2996 1.62004 12.4241C3.88692 15.9725 7.58442 18.7475 11.9841 18.7475C16.336 18.7475 20.1094 15.9641 22.3805 12.4077C22.4591 12.2856 22.5009 12.1435 22.5009 11.9982C22.5009 11.853 22.4591 11.7109 22.3805 11.5888C20.1043 8.07317 16.3032 5.24754 11.9841 5.24754Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 15.7494C14.0712 15.7494 15.7501 14.0704 15.7501 11.9994C15.7501 9.92829 14.0712 8.24936 12.0001 8.24936C9.92905 8.24936 8.25012 9.92829 8.25012 11.9994C8.25012 14.0704 9.92905 15.7494 12.0001 15.7494Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
